import React from "react"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import Link from "decentraland-gatsby/dist/plugins/intl/Link"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Hero } from "decentraland-ui/dist/components/Hero/Hero"

import { SectionViewedTrack } from "../../../modules/segment"

import "./CreatorsJumpIn.css"

export const CreatorsJumpIn = () => {
  const l = useFormatMessage()
  return (
    <>
      <div className="creators-jump-in">
        <SectionTrack sectionViewed={SectionViewedTrack.CreatorsJumpIn} />
        <Hero centered>
          <Hero.Header>
            {l("component.creators_landing.jump_in.title", {
              hightlight: (
                <span>
                  {l("component.creators_landing.jump_in.title_hightlight")}
                </span>
              ),
            })}
          </Hero.Header>
          <Hero.Actions>
            <Button
              as={Link}
              href={l("component.creators_landing.jump_in.button.url")}
              primary={true}
            >
              {l("component.creators_landing.jump_in.button.label")}
            </Button>
          </Hero.Actions>
          <Hero.Content
            className={TokenList.join(["jump-in__content"])}
          ></Hero.Content>
        </Hero>
      </div>
    </>
  )
}
