export enum SegmentEvent {
  Download = "Download",
  ExploreOnWeb = "Explore on web",
  OpenSendToDesktop = "Open Send To Desktop",
  MicrosoftStore = "Go to Microsoft Store",
  AppStore = "Go to AppStore",
  SubscribeToMobile = "Subscribe To Mobile",
  GoToExplorer = "Go To Explorer",
  SeeFestivalEvents = "See Festival Events Schedule",
  GoToExplorerMvmfLiveEvent = "Go To Explorer MVMF Live Event",
  WatchMore = "Watch More",
  YourTutorial = "Your Tutorial",
  BrowserCreators = "Browser Creators",
  AreYouCreator = "Are You A Creator",
  CreatorsConnectCard = "Creators Connect Card",
}

export enum SectionViewedTrack {
  CreatorsConnect = "Creators Connect",
  CreatorsCreate = "Creators Create",
  CreatorsEarn = "Creators Earn",
  CreatorsHero = "Creators Hero",
  CreatorsJumpIn = "Creators Jump In",
  CreatorsLearn = "Creators Learn",
  CreatorsWhy = "Creators Why",
  CreatorsFaqs = "Creators Faqs",
  LandingEventsPlacesFeed = "Landing Events Places Feed",
  LandingBlogFeed = "Landing Blog Feed",
  LandingAbout = "Landing About",
  LandingMissions = "Landing Missions",
  LandingMarketplace = "Landing Marketplace",
  LandingTrending = "Landing Trending",
  LandingWorlds = "Landing Worlds",
  LandingFaqs = "Landing Faqs",
}
